<template>
    <div class="page">
        <div class="login_content">
            <div class="logo_words">
                <div class="login_left_tit">
                    <div class="top">
                        <img src="../../assets/img/3.png" alt />
                        <span class="title" style="font-size:46px;padding-left:10px">动销管理系统</span>
                    </div>
                    <div>
                        <span style="display:block;padding-top:20px" class="title">实现销售业务互联网数字化管理</span>
                        <span style="display:block;" class="title">全面提升企业效率</span>
                    </div>
                </div>
            </div>
            <a-form id="login_form" :form="form" class="login_form" @submit="handleSubmit">
                <div class="login">登录</div>
                <a-form-item>
                    <a-input class="input" v-decorator="['username',{ rules: [{ required: true, message: '请输入用户名' }] }]" placeholder="请输入用户名">
                        <a-icon slot="prefix" type="user" />
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-input class="input" v-decorator="['password',{ rules: [{ required: true, message: '请输入密码' }] }]" type="password" placeholder="请输入密码">
                        <a-icon slot="prefix" type="lock" />
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <!-- <a-checkbox v-decorator="['remember',{valuePropName: 'checked',initialValue: true}]">下次自动登录</a-checkbox>
                    <a class="login-form-forgot" href="">忘记密码</a> -->
                    <a-button type="primary" html-type="submit" class="login-form-button">登 录</a-button>
                </a-form-item>
            </a-form>
        </div>
        <div class="copyright">
            <span>Copyright © 2020 江西润田实业股份有限公司 赣ICP备16012686号-1</span>
            <span>哇酷科技提供技术支持 {{this.$config.version}}</span>
        </div>
    </div>
</template>

<script>
import { login } from '@/api/user'
export default {
    data() {
        return {
            // form:null,
        }
    },
    created() {
        window.parent.postMessage({
            data:{
                pageType:"login"
            }
        },'*');
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'login' });
        this.$method.remove_local('token');
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    let data = await login(values);
                    this.$method.set_local('token', data.data.token);
                    if(data.data.url){
                        this.$router.replace(data.data.url)
                    }else{
                        this.$router.replace('/')
                    }
                }
            });
        }
    }
}
</script>

<style lang="less">
.login_left_tit {
    width: 430px;
}
.page {
    width: 100%;
    height: 100%;
    background: url(../../assets/img/back.png) no-repeat center;
    background-size: cover;
    overflow: hidden;
}
.login_content {
    display: flex;
    padding-top: 160px;
    width: 1200px;
    margin: 0 auto;
}
.logo_words {
    // margin: 160px 0 0 400px;
    // float: left;
    flex: 1;
    .top {
        line-height: 47px;
    }
    img {
        width: 117px;
        height: 47px;
        float: left;
    }
    .title {
        color: #fff;
        font-size: 26px;
        // float: left;
    }
}
.login_form {
    background: #fff;
    width: 350px;
    // margin: 160px auto 0;
    padding: 40px 32px;
    box-sizing: border-box;
    // float: right;
    // margin-right: 300px;
    .login {
        font-size: 32px;
        line-height: 32px;
        padding-bottom: 40px;
    }
    .login-form-button {
        width: 100%;
        height: 40px;
        margin-top: 20px;
    }
    .input {
        background-color: #fff;
    }
}
.copyright {
    position: fixed;
    bottom: 40px;
    left: 0;
    right: 0;
    span {
        display: block;
        color: #fff;
        text-align: center;
    }
}
</style>